import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import { WrapMax } from '$components/Wraps';
import { BlockImageFullWidthProps } from './types';
import Caption from './Caption';
import Markdown from '$components/Markdown';

const StyledImageFullWidth = styled(WrapMax)`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlockImageFullWidth: React.FC<BlockImageFullWidthProps> = ({ image, caption }) => (
  <StyledImageFullWidth>
    <GatsbyImage image={getImageData(image?.imageFile)} alt={image?.alternativeText} />
    {caption && (
      <Caption>
        <Markdown content={caption} />
      </Caption>
    )}
  </StyledImageFullWidth>
);
export default BlockImageFullWidth;
